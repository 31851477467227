import React from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup'
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from './authContext';
import './authlogin.css'
import logo from '../assets/images/svg/logo.svg'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import { titleCase } from '../utils/exportFunction/util';

export const Login = (props) => {
  const { login } = useAuth()
  const navigate = useNavigate()
  const url = process.env.REACT_APP_PAYSTREET_URL + 'adminlogin'
  return (
    <div className='bg-dark text-white main-login-page'>
      <Container className="pt-5">
        <nav class="navbar navbar-dark bg-dark m-auto">
          <Link>
            <img src={logo} alt="PayStreet" height={22} />
          </Link>
        </nav>
        <Row className="justify-content-center pt-5">
          <Col md={6}>
            <h2 >Sign In</h2>
            <Formik
              initialValues={{
                email: '',
                password: '',
              }}
              validationSchema={Yup.object({
                email: Yup.string().required('Email is required'),
                password: Yup.string().required('Password is required'),
              })}
              onSubmit={(values, { setSubmitting }) => {
                // if (values) {
                axios.post(url, values).then((res) => {
                  console.log('res', res)
                  if (res.status === 200) {
                    setSubmitting(false)
                    login(res)
                    localStorage.setItem('token', res.data.access_token)
                    navigate('/admin')
                  } else {
                    console.log('test')
                  }
                }).catch((error) => {
                  toast.error(titleCase(error.response.data.message), {
                    position: toast.POSITION.BOTTOM_LEFT
                  })
                })
                // } else {
                //   localStorage.setItem('token', null)
                //   alert('Please fill right credential')
                // }
              }}
            >
              <Form>
                <div className="form-group p-2">
                  <label htmlFor="email">Email</label>
                  <Field
                    type="text"
                    name="email"
                    className="form-control"
                    placeholder="Please enter your email"
                  />
                  <ErrorMessage name="email" component="div" style={{ fontSize: "small" }} className="text-danger" />
                </div>

                <div className="form-group p-2">
                  <label htmlFor="password">Password</label>
                  <Field
                    type="password"
                    name="password"
                    className="form-control"
                    placeholder="Password"
                  />
                  <ErrorMessage name="password" component="div" style={{ fontSize: "small" }} className="text-danger" />
                </div>
                <div className=' p-2'>
                  <Button type="submit" variant="success" block>
                    Login
                  </Button>
                </div>
              </Form>
            </Formik>
          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </div>
  );
}
