import axios from 'axios';
import React, { createContext, useState, useContext } from 'react';
import { titleCase } from '../utils/exportFunction/util';
import { toast } from 'react-toastify';


const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [bearer, setBearer] = useState(null)

  const logOutWithApi = () => {
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_PAYSTREET_URL + 'adminlogout',
      headers: {
        'Authorization': bearer
      }
    };

    axios.request(config)
      .then((response) => {
        toast.info(titleCase(response.data.message), {
          position: toast.POSITION.BOTTOM_LEFT
        })
      })
      .catch((error) => {
        console.log(error);
      })
  }

  const login = (userData) => {
    setBearer(userData.data.access_token)
    setUser(userData.data)
  };
  const logout = () => {
    setUser(null)
    setBearer(null)
    localStorage.removeItem('token')
    logOutWithApi()
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, bearer }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
