import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { AccountOpeningTable } from './table/accountOpeningTable'
import { NavbarAdmin } from './navbar'
import '../admin.css'

export const Index = () => {
  const [show, setShow] = useState(1)
  return (
    <>
      <Row>
        <Col md={2} className="sidebar">
          <NavbarAdmin setShow={setShow} />
        </Col>
        <Col md={10} className="sidebar">
          <div>
            <header className="main-header card">
              <p className='a-hadding'>{show === 1 ? 'Subscriber email' : 'Account open email'}</p>
            </header>
          </div>
          <div className='right-table'>
            <AccountOpeningTable show={show} />
          </div>

        </Col>
      </Row>
    </>
  )
}
