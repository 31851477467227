import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../auth/authContext'
import axios from 'axios'

export const AccountOpeningTable = (props) => {
  const { bearer } = useAuth()
  const [subsciberList, setSubsciberList] = useState([])
  const [onboardList, setOnboardList] = useState([])
  // const [subuserLenght, setsubUserLenght] = useState()
  // const [onboarduserLenght, setonboardUserLenght] = useState()
  const subscribeGet = () => {
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_PAYSTREET_URL + 'GetsubscriberList',
      headers: {
        'Authorization': bearer || localStorage.getItem('token')
      },
      data: ''
    };

    axios.request(config)
      .then((response) => {
        setSubsciberList(response.data);
        // setsubUserLenght(response.data.data.length)
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const onboardGet = () => {
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_PAYSTREET_URL + 'GetOnBoardList',
      headers: {
        'Authorization': bearer || localStorage.getItem('token')
      },
      data: ''
    };

    axios.request(config)
      .then((response) => {
        setOnboardList(response.data);
        // setonboardUserLenght(response.data.data.length)
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    props.show === 1 ?
      subsciberList.length === 0 && subscribeGet()
      :
      onboardList.length === 0 && onboardGet()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bearer, props.show])

return (
  <>
    {/* <p ><strong> Users number: {props.show === 1 ? subuserLenght : onboarduserLenght } </strong></p> */}
    <div className="card h-100 transactions-list has-table">
      <div className="card-body has-table" >
        <div id="scrollableDiv" className="auto-load-table">
          {props.show === 1 ?
            <table className="table ">
              <thead>
                <tr>
                  <th scope="col">
                    {/* <input type='checkbox' /> */}
                    No.
                  </th>
                  <th>_id</th>
                  <th>Subscriber Email</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody >
                {subsciberList.data?.map((item, index) => {
                  return (<tr key={index}>
                    <td>
                      {/* <input
                        type="checkbox"
                        // checked={item.selected}
                        // value={item.createdAt}
                        className="form-check-input"
                        id="rowcheck{item.id}"
                      // onChange={(e) => onItemCheck(e, item)}
                      /> */}
                      {index + 1}
                    </td>
                    <td>{item._id}<span className="row-highlighted"></span></td>
                    <td>{item.subscriberEmail}</td>
                    <td>{item.timestamp}</td>
                  </tr>)
                })}

              </tbody>
            </table> :
            <table className="table ">
              <thead>
                <tr>
                  <th scope="col">
                    {/* <input type='checkbox' /> */}
                    No.
                  </th>
                  <th>_id</th>
                  <th>Account Open Email</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {onboardList.data?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        {/* <input
                          type="checkbox"
                          // checked={item.selected}
                          // value={item.createdAt}
                          className="form-check-input"
                          id="rowcheck{item.id}"
                        // onChange={(e) => onItemCheck(e, item)}
                        /> */}
                        {index + 1}
                      </td>
                      <td>{item._id}<span className="row-highlighted"></span></td>
                      <td>{item.accountOpeningRequestEmail}</td>
                      <td>{item.timestamp}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>}
        </div>
      </div>
    </div>
  </>
)
}
