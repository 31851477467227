import React from 'react'
import { useAuth } from '../auth/authContext'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { Login } from '../auth/login'
import { Index } from '../view/admin'

const AppRouter = () => {

    // const { user } = useAuth()
    const { bearer } = useAuth()
    const auth = localStorage.getItem('token')
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' Component={Login} />
                <Route
                    path="/admin"
                    element={(bearer || auth) ? <Index /> : <Navigate to="/" />}
                />
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </BrowserRouter>
    )
}

export default AppRouter