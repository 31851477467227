import React, { useState } from 'react'
import { logo, logouticon } from '../../utils/exportFunction/icons'
import { Link } from 'react-router-dom'
import '../../view/admin.css'
import { useAuth } from '../../auth/authContext'
import { FaLockOpen } from "react-icons/fa";
import { MdUnsubscribe } from "react-icons/md";
import { Image } from 'react-bootstrap'

export const NavbarAdmin = (props) => {
    const [isSubscriptionActive, setSubscriptionActive] = useState(true)
    const [isAccountActive, setAccountActive] = useState(false)
    const { logout } = useAuth();

    const onClickSubscriptionHandle = () => {
        props.setShow(1)
        setSubscriptionActive(true);
        setAccountActive(false);
    }

    const onClickAccountHandle = () => {
        props.setShow(2)
        setSubscriptionActive(false);
        setAccountActive(true);
    }

    const navlogout = () => {
        logout()
    }

    return (
        <section className="global-sidebar">
            <div className="main-div">
                <div className="main-logo">
                    <Link>
                        <img src={logo} alt="PayStreet" />
                    </Link>
                </div>
                <div className="sidemenu mt-5">
                    <ul className=" sidemenu-content">
                        <li>
                            <button
                                className={`link btn btn-link ${isSubscriptionActive ? 'active-btn' : ''}`}
                                onClick={onClickSubscriptionHandle}
                            >
                                <MdUnsubscribe color={isSubscriptionActive ? 'white' : 'green'} fontSize={18} />  <span >Subscription</span>
                            </button>
                        </li>

                        <li>
                            <button
                                // path={collections}
                                className={`link btn btn-link ${isAccountActive ? 'active-btn' : ''}`}
                                onClick={onClickAccountHandle}
                            >
                                <FaLockOpen color={isAccountActive ? 'white' : 'green'} fontSize={18} />  <span >Account Opening</span>
                            </button>
                        </li>
                    </ul>
                </div>
                <div className='logout '>
                    <Link
                        className="btn btn-link"
                        onClick={navlogout}
                    >
                        <Image src={logouticon} alt="Logout"/>
                        <span >Logout</span>
                    </Link>
                </div>
            </div>
        </section>
    )
}
